import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';

import { GlossarySearch } from '@pages-impl/glossary';

import * as styles from './glossary-search.module.scss';

import featuredImage from '@images/header-images/featured_image_og_codilime_glossary.jpg';

export default function Glossary(props) {
  return (
    <Layout {...props} oldPage>
      <SEO
        title="Glossary of programming and networking terms - CodiLime"
        description="In CodiLime's networking glossary, we’ve explained common networking terminology to help you learn more. Give them a read below."
        featuredImage={featuredImage}
      />
      <Header
        title="CodiLime Glossary"
        descriptionText="Some common networking terms clearly explained"
        id="glossary_header"
        src="header-images/glossary_bg.jpg"
        className={styles.header}
      />
      <GlossarySearch data={props.data} />
    </Layout>
  );
}

export const newsQuery = graphql`
  query GlossaryByPath {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "glossary" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      nodes {
        frontmatter {
          path
          title
          glossarywordId
          relatedGlossaryWords
        }
        html
      }
    }
  }
`;
