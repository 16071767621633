import React, { useEffect, useMemo, useState } from 'react';

import { filterGlossaryByKeywords, parseGraphResultToArrayNoEdges } from '@src/utils/parsers';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { SearchBarAlphabet } from '@commons/search-bar-alphabet/SearchBarAlphabet';

import { SearchContent } from '../search-content/SearchContent';

import * as styles from './glossary-search.module.scss';

const alphabet = Array.from(Array(26))
  .map((_, i) => i + 65)
  .map((x) => String.fromCharCode(x));

export const GlossarySearch = ({ data }) => {
  const [keywords, setKeywords] = useState('');
  const [activeLetter, setActiveLetter] = useState('');

  useEffect(() => {
    uniqueLetters.some((letter) =>
      glossaryWordsInArray.map(({ title }) => title.startsWith(letter) && setActiveLetter(letter)),
    );
  }, []);

  const glossaryWordsInArray = useMemo(
    () => filterGlossaryByKeywords(parseGraphResultToArrayNoEdges(data), keywords),
    [keywords],
  );
  const uniqueLetters = glossaryWordsInArray
    .map(({ title }) => title.slice(0, 1))
    .filter((item, i, ar) => ar.indexOf(item) === i);

  return (
    <StandardSection id="trusted_by" className="mvppage">
      <div className={styles.searchWrapper}>
        <SearchBarAlphabet
          setSearchTerm={setKeywords}
          searchTerm={keywords}
          activeLetter={activeLetter}
          categories={alphabet}
          uniqueLetters={uniqueLetters}
          setActiveLetter={setActiveLetter}
        />
      </div>
      <SearchContent activeLetter={activeLetter} uniqueLetters={uniqueLetters} glossaryContent={glossaryWordsInArray} />
    </StandardSection>
  );
};
