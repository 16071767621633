const scrollDirections = {
  left: 'left',
  right: 'right',
};

const useSlider = (itemsRef, value) => {
  const handleScroll = (direction) => {
    let scrollCompleted = 0;
    const container = itemsRef.current;

    const slide = setInterval(() => {
      if (direction === scrollDirections.left) {
        container.scrollLeft -= 40;
      } else {
        container.scrollLeft += 40;
      }
      scrollCompleted += 40;

      if (scrollCompleted >= value) {
        window.clearInterval(slide);
      }
    }, 40);
  };

  return { handleScroll };
};

export default useSlider;
