import { useState, useRef } from 'react';
import React, { useEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { Link } from '@commons/link/Link';

import * as styles from './search-content.module.scss';

export const SearchContent = ({ activeLetter, uniqueLetters, glossaryContent }) => {
  const divRef = useRef();
  const [column, setColumn] = useState();

  const { xs, sm, md, l } = useBreakpoint();

  useEffect(() => {
    if (divRef.current.clientHeight < 400 && !sm) {
      return setColumn(2);
    }

    setColumn(false);
  }, [xs, sm, md, l, activeLetter]);

  return (
    <div ref={divRef} className={styles.searchContent} style={{ maxHeight: '3000px', columns: column }}>
      {uniqueLetters.map((letter, index) => (
        <div className={styles.resultWrapper} key={index + letter}>
          {activeLetter <= letter && <h2>{letter}</h2>}
          {glossaryContent.map(({ title, path }, index) => {
            if (title.startsWith(letter) && activeLetter <= letter) {
              return (
                <Link className={styles.link} href={path} key={index}>
                  {title}
                </Link>
              );
            }
          })}
        </div>
      ))}
    </div>
  );
};
