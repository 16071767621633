import React, { useRef } from 'react';
import classNames from 'classnames';

import { safeMap } from '@src/utils/mapper';

import useSlider from '@hooks/useSlider';
import leftArrow from '@images/left-arrow.svg';
import rightArrow from '@images/right-arrow.svg';
import { SearchForm } from '@commons/search-form/SearchForm';

import * as styles from './search-bar-alphabet.module.scss';

export const SearchBarAlphabet = ({
  activeLetter,
  setActiveLetter,
  uniqueLetters,
  categories,
  searchTerm,
  setSearchTerm,
}) => {
  const itemsRef = useRef(null);
  const availableItem = (category) => uniqueLetters.includes(category);

  const { handleScroll } = useSlider(itemsRef, 50);

  const setLetter = (category) => {
    if (availableItem(category)) {
      setActiveLetter(category);
    }
  };

  const handleChangeInput = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={styles.searchBar}>
      <SearchForm handleInput={handleChangeInput} searchTerm={searchTerm} />
      <div className={styles.navigation}>
        <div className={styles.leftArrow} onClick={() => handleScroll('left')}>
          <img loading="lazy" src={leftArrow} alt="arrow" />
        </div>
        <div className={styles.items} ref={itemsRef}>
          {safeMap(categories, (category, index) => (
            <button
              onClick={() => setLetter(category)}
              key={index}
              className={classNames(styles.item, {
                [styles.selectedItem]: activeLetter === category,
                [styles.availableItem]: availableItem(category),
              })}
            >
              {category}
            </button>
          ))}
        </div>
        <div className={styles.rightArrow} onClick={() => handleScroll('right')}>
          <img loading="lazy" src={rightArrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};
